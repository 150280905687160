import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import Loader from 'src/components/Loader';
import NothingToShow from 'src/components/NothingToShow';
import Pagination from 'src/components/Pagination';
import UserPanel from 'src/components/SidePanels/SidePanel/FeaturedSidePanel/UserPanel';
import TableList from 'src/components/TableList';

import { ADMIN_USERS_ROUTE } from 'src/constants/routes';

import useTexts from 'src/hooks/useTexts';

import { useAppDispatch } from 'src/store/hooks';
import { setSelectedUserInstance } from 'src/store/reducers/userSlice';

import { User } from 'src/types/dao/user.types';

import { renderUserCell, tableHeaders } from './common';
import styles from './styles.module.scss';

export type Props = {
  data: Array<User>;
  className?: string;
  isLoading?: boolean;
  paginationConfig: {
    loadNext: () => void;
    loadPrev: () => void;
    pageNumber: number;
    canLoadNext: boolean;
    canLoadPrev: boolean;
  };
  navigateToUser: (agentId: string, event: React.MouseEvent) => void;
};

type TextGetter = (key: string) => string | undefined;

export type UserTableActionConfig = {
  navigateToUser: (agentId: string, event: React.MouseEvent) => void;
  t: TextGetter;
};
const UsersList: FC<Props> = ({
  data,
  isLoading,
  paginationConfig,
  navigateToUser,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTexts();
  const history = useHistory();
  const [showSidePanel, setShowSidePanel] = useState<boolean>(false);

  const sortedData = data
    .slice()
    .sort(
      (a, b) =>
        new Date(b.last_login).getTime() - new Date(a.last_login).getTime(),
    );

  const handleUserClick = useCallback(
    async (user: User) => {
      await dispatch(setSelectedUserInstance(user));
      history.push(`${ADMIN_USERS_ROUTE}/${user.id}`);
      setShowSidePanel(true);
    },
    [dispatch, history],
  );

  const onCloseSidePanel = () => {
    setShowSidePanel(false);
    dispatch(setSelectedUserInstance(null));
    history.push(ADMIN_USERS_ROUTE);
  };

  useEffect(() => {
    const path = window.location.pathname;
    const userId = path.split('/').pop();

    if (userId) {
      const user = data.find((user) => user.id === userId);
      if (user) {
        dispatch(setSelectedUserInstance(user));
        setShowSidePanel(true);
      } else {
        history.push(ADMIN_USERS_ROUTE);
      }
    }
  }, [data, dispatch, history]);

  const tableActionConfig: UserTableActionConfig = {
    navigateToUser,
    t,
  };

  const nothingToShow = sortedData.length === 0;

  return (
    <>
      {isLoading ? (
        <div className={styles['loader-wrapper']}>
          <Loader absolute backdrop />
        </div>
      ) : (
        <div className='table-wrapper'>
          <TableList<User, UserTableActionConfig>
            data={sortedData}
            headers={tableHeaders}
            renderCell={renderUserCell}
            onCellClick={handleUserClick}
            tableActionConfig={tableActionConfig}
          />
          <div className='table-wrapper_pagination'>
            {nothingToShow ? (
              <NothingToShow />
            ) : (
              <Pagination
                loadNextPage={paginationConfig.loadNext}
                loadPrevPage={paginationConfig.loadPrev}
                pageNumber={paginationConfig.pageNumber}
                canLoadNext={paginationConfig.canLoadNext}
                canLoadPrev={paginationConfig.canLoadPrev}
              />
            )}
          </div>
        </div>
      )}
      <UserPanel onPanelClose={onCloseSidePanel} isOpen={showSidePanel} />
    </>
  );
};

export default UsersList;
