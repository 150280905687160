import classNames from 'classnames';
import { AllFilter } from 'common-ui';
import React, { FC } from 'react';

import DatePickerComponent from 'src/components/DatePickerComponent';
import Loader from 'src/components/Loader';
import ConfirmModal from 'src/components/Modals/ConfirmModal';
import NothingToShow from 'src/components/NothingToShow';
import Pagination from 'src/components/Pagination';
import Search from 'src/components/Search';
import Select from 'src/components/Select';
import AgentPanel from 'src/components/SidePanels/SidePanel/FeaturedSidePanel/AgentPanel';
import TableList from 'src/components/TableList';

import useTexts from 'src/hooks/useTexts';

import {
  Agent,
  AgentStatus,
  AgentStatusFilter,
} from 'src/types/dao/agent.types';

import { renderAgentCell, tableHeaders } from './common';
import { useComponentProps } from './hooks/useComponentProps';
import styles from './styles.module.scss';

type TextGetter = (key: string) => string | undefined;

export type AgentTableActionConfig = {
  canEdit: boolean;
  handleEdit: () => void;
  canCopy: boolean;
  handleCopy: () => void;
  quickEditingClick: (agentId: string, event: React.MouseEvent) => void;
  canArchive: boolean;
  handleArchive: () => void;
  navigateToAgent: (agentId: string, event: React.MouseEvent) => void;
  t: TextGetter;
};

const Agents: FC = () => {
  const { t } = useTexts();

  const {
    search,
    setSearch,
    searchOpen,
    setSearchOpen,
    statusFilter,
    setStatusFilter,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    page,
    setPage,
    havingNextPage,
    adminAgents,
    isLoading,
    activeAgent,
    setActiveAgent,
    showSidePanel,
    handleAgentInfoClick,
    quickEditingClick,
    onCloseSidePanel,
    canCreate,
    handleCreate,
    canEdit,
    handleEdit,
    canArchive,
    archiveModalOpen,
    setArchiveModalOpen,
    archiveAgent,
    isArchiveAgentLoading,
    canCopy,
    copyModalOpen,
    setCopyModalOpen,
    copyAgent,
    isCopyAgentLoading,
    navigateToAgent,
  } = useComponentProps();

  const statusOptions = [
    ...Object.values(AllFilter),
    ...Object.values(AgentStatus),
  ].map((key) => ({
    value: key,
    label: t('general.' + key),
  }));

  const tableActionConfig: AgentTableActionConfig = {
    canEdit,
    handleEdit,
    canCopy,
    handleCopy: () => setCopyModalOpen(true),
    canArchive,
    handleArchive: () => setArchiveModalOpen(true),
    quickEditingClick,
    navigateToAgent,
    t,
  };

  return (
    <div className={styles['page']}>
      <div className={styles['info-group']}>
        <div className={styles['info-search']}>
          <Search
            value={search}
            onChange={setSearch}
            searchOpen={searchOpen}
            setSearchOpen={(searchOpen: boolean) => {
              setSearchOpen(searchOpen);
              if (!searchOpen) {
                setSearch('');
              }
            }}
            iconClassName={styles['info-search-icon']}
          />
        </div>
        <div className={styles['info-title-container']}>
          <h2>{t('pages.agents')}:</h2>
          {canCreate && (
            <div
              className={styles['create-button-wrapper']}
              onClick={handleCreate}
            >
              {t('agent.create_agent')}
            </div>
          )}
        </div>
        <div
          className={classNames(styles['agents-wrapper'], {
            [styles['search-open']]: searchOpen,
          })}
        >
          <div className={styles['filter-wrapper']}>
            <p>{t('general.filter_by')}:</p>

            <div className={styles['filters']}>
              <div className={styles['filters-agent-status']}>
                <div className={styles['filters-agent-status_select']}>
                  <Select
                    label={t('agent.status')}
                    options={statusOptions}
                    value={statusFilter}
                    onSelect={(value: string) =>
                      setStatusFilter(value as AgentStatusFilter)
                    }
                    theme='white'
                    size='small'
                  />
                </div>
              </div>
              <div className={styles['filters-date-picker']}>
                <DatePickerComponent
                  start={startDate}
                  end={endDate}
                  setStart={setStartDate}
                  setEnd={setEndDate}
                />
              </div>
            </div>
          </div>
          {isLoading ? (
            <div className={styles['loader-wrapper']}>
              <Loader absolute backdrop />
            </div>
          ) : (
            <div className='table-wrapper'>
              {adminAgents.length ? (
                <>
                  <TableList<Agent, AgentTableActionConfig>
                    data={adminAgents}
                    headers={tableHeaders}
                    renderCell={renderAgentCell}
                    onCellClick={handleAgentInfoClick}
                    onActionClick={setActiveAgent}
                    tableActionConfig={tableActionConfig}
                  />
                  <div className='table-wrapper_pagination'>
                    <Pagination
                      loadNextPage={() => setPage(page + 1)}
                      loadPrevPage={() => setPage(page - 1)}
                      pageNumber={page}
                      canLoadNext={havingNextPage || false}
                      canLoadPrev={page > 1}
                    />
                  </div>
                </>
              ) : (
                <NothingToShow />
              )}
            </div>
          )}
        </div>
        <AgentPanel onPanelClose={onCloseSidePanel} isOpen={showSidePanel} />
      </div>
      {archiveModalOpen && activeAgent && (
        <ConfirmModal
          setOpen={setArchiveModalOpen}
          title={t('agent.archive_agent')}
          onConfirm={() => archiveAgent({ agentId: activeAgent.id })}
          confirmButtonLabel={t('form.confirm')}
          isConfirmLoading={isArchiveAgentLoading}
          size='sm'
        >
          <p className={styles['modal-subtitle']}>
            {t('agent.archive_agent_desc')}
          </p>
        </ConfirmModal>
      )}
      {copyModalOpen && activeAgent && (
        <ConfirmModal
          setOpen={setCopyModalOpen}
          title={t('agent.copy_agent')}
          onConfirm={() => copyAgent({ agentId: activeAgent.id })}
          confirmButtonLabel={t('form.confirm')}
          isConfirmLoading={isCopyAgentLoading}
          size='sm'
        >
          <p className={styles['modal-subtitle']}>
            {t('agent.copy_agent_desc')}
          </p>
        </ConfirmModal>
      )}
    </div>
  );
};

export default Agents;
