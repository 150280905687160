import { AllFilter, getTierWeight, UserTier } from 'common-ui';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  useApiAdminGetAgentCheckpoints,
  useApiAdminGetAgentInstances,
  useApiAdminGetAgents,
} from 'src/api/agent/hooks/useApiAdminGet';
import {
  useApiArchiveAgent,
  useApiCopyAgent,
} from 'src/api/agent/hooks/useApiManage';
import { useApiAdminGetInvitations } from 'src/api/invitation/hooks/useApiAdminGet';
import { useApiSendInvitations } from 'src/api/invitation/hooks/useApiAdminManage';
import { useApiAdminGetUsers } from 'src/api/user/hooks/useApiAdminGet';
import { useApiUpdateUser } from 'src/api/user/hooks/useApiAdminManage';

import { AGENT_EDIT_ROUTE, AGENT_VIEW_ROUTE } from 'src/constants/routes';

import { dateToUnixTimestamp } from 'src/helpers/datetime';

import useFormData from 'src/hooks/useFormData';

import { useAppSelector } from 'src/store/hooks';
import { getCurrentUser } from 'src/store/reducers/userSlice/selectors';

import {
  Agent,
  AgentStatus,
  AgentStatusFilter,
} from 'src/types/dao/agent.types';
import { InvitationFormData } from 'src/types/dao/invitation.types';

type Props = {
  id: string;
  activeAgent: Agent | null;
};

const initialFormData: InvitationFormData = {
  minutes: 0,
  count: 1,
};

const useComponentProps = ({ id, activeAgent }: Props) => {
  const oneMonthAgo = new Date();
  oneMonthAgo.setDate(oneMonthAgo.getDate() - 30);
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
  const now = new Date();
  const history = useHistory();
  const [startDate, setStartDate] = useState<Dayjs>(dayjs(oneMonthAgo));
  const [endDate, setEndDate] = useState<Dayjs>(dayjs(now));
  const [startAgentDate, setStartAgentDate] = useState<Dayjs>(
    dayjs(oneYearAgo),
  );
  const [endAgentDate, setEndAgentDate] = useState<Dayjs>(dayjs(now));
  const [invitationModalOpen, setInvitationModalOpen] =
    useState<boolean>(false);
  const { formData, formDataSetters, resetFormData } =
    useFormData<InvitationFormData>({ initialData: initialFormData });
  const adminUser = useAppSelector(getCurrentUser);
  const [accessTierModalOpen, setAccessTierModalOpen] =
    useState<boolean>(false);
  const [unsavedAccessTier, setUnsavedAccessTier] = useState<UserTier>(
    UserTier.REGULAR,
  );
  const [agentStatusFilter, setAgentStatusFilter] = useState<AgentStatusFilter>(
    AllFilter.ALL,
  );
  const [copyModalOpen, setCopyModalOpen] = useState<boolean>(false);
  const [archiveModalOpen, setArchiveModalOpen] = useState<boolean>(false);

  // USERS API
  const {
    doRequest: getUserById,
    isLoading: isCurrentUserLoading,
    responseData: singleUser,
  } = useApiAdminGetUsers();

  const { doRequest: getAgentCheckpoints, responseData: agentCheckpoints } =
    useApiAdminGetAgentCheckpoints();

  const { doRequest: getAgentInstances, responseData: agentInstances } =
    useApiAdminGetAgentInstances();

  const {
    doRequest: getInvitationsSubmittedBy,
    isLoading: isInvitationsSubmittedByLoading,
    responseData: invitationsSubmittedBy,
  } = useApiAdminGetInvitations();
  const {
    doRequest: getInvitationsCreatedBy,
    isLoading: isInvitationsCreatedByLoading,
    responseData: invitationsCreatedBy,
  } = useApiAdminGetInvitations();
  const {
    doRequest: adminSendInvitations,
    isLoading: isSendInvitationLoading,
  } = useApiSendInvitations({
    onSuccess: useCallback(() => {
      setInvitationModalOpen(false);
      resetFormData();
    }, [resetFormData]),
  });
  const { doRequest: adminUpdateuser, isLoading: isUpdateUserLoading } =
    useApiUpdateUser({
      onSuccess: useCallback(() => {
        setAccessTierModalOpen(false);
        getUserById({ ids: [id] });
      }, [getUserById, id]),
    });

  const currentUser = useMemo(
    () => singleUser?.data && singleUser.data.find((user) => user.id === id),
    [singleUser, id],
  );

  useEffect(() => {
    getInvitationsSubmittedBy({ filters: { redeemed_by: id } });
    getInvitationsCreatedBy({ filters: { created_by: id } });
    getUserById({ ids: [id] });
    getAgentCheckpoints({ owned_by: id });
    getAgentInstances({ owned_by: id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentUser) {
      setUnsavedAccessTier(currentUser.access_tier);
    }
  }, [currentUser]);

  const handleSendInvitationClick = useCallback(() => {
    setInvitationModalOpen(true);
  }, []);

  const handleSendInvitation = useCallback(() => {
    if (currentUser) {
      adminSendInvitations({
        formData: { ...formData, emails: [currentUser.email] },
      });
    }
  }, [adminSendInvitations, formData, currentUser]);

  const handleUpdateAccessTierClick = useCallback((value: string) => {
    setUnsavedAccessTier(value as UserTier);
    setAccessTierModalOpen(true);
  }, []);

  const handleUpdateAccessTier = useCallback(() => {
    if (currentUser) {
      adminUpdateuser({
        formData: { id: currentUser.id, access_tier: unsavedAccessTier },
      });
    }
  }, [adminUpdateuser, currentUser, unsavedAccessTier]);

  const {
    doRequest: adminGetAgents,
    responseData: adminAgents,
    isLoading: isAgentLoading,
    nextPage: nextAgentPage,
    prevPage: prevAgentPage,
  } = useApiAdminGetAgents();

  const loadAgents = useCallback(
    (page = 1) => {
      const queryParams: {
        from_time: number;
        to_time: number;
        owned_by: string;
        status?: string;
      } = {
        from_time: dateToUnixTimestamp(startAgentDate.toDate()),
        to_time: dateToUnixTimestamp(endAgentDate.toDate()),
        owned_by: id,
      };
      if (agentStatusFilter === AllFilter.ALL) {
        adminGetAgents(queryParams, page);
      } else {
        queryParams.status = agentStatusFilter;
        adminGetAgents(queryParams, page);
      }
    },
    [adminGetAgents, agentStatusFilter, endAgentDate, id, startAgentDate],
  );

  const loadNextAgent = useCallback(() => {
    if (nextAgentPage) {
      loadAgents(nextAgentPage);
    }
  }, [nextAgentPage, loadAgents]);

  const loadPrevAgent = useCallback(() => {
    if (prevAgentPage) {
      loadAgents(prevAgentPage);
    }
  }, [prevAgentPage, loadAgents]);

  const agentsToShow = useMemo(() => {
    if (adminAgents) {
      if (agentStatusFilter === AllFilter.ALL) {
        return adminAgents.data;
      } else {
        return adminAgents.data.filter(
          (agent) => agent.status === agentStatusFilter,
        );
      }
    }
    return [];
  }, [adminAgents, agentStatusFilter]);

  const { doRequest: archiveAgent, isLoading: isArchiveAgentLoading } =
    useApiArchiveAgent({
      onSuccess: useCallback(() => {
        setArchiveModalOpen(false);
        loadAgents();
      }, [loadAgents]),
    });

  const { doRequest: copyAgent, isLoading: isCopyAgentLoading } =
    useApiCopyAgent({
      onSuccess: useCallback(() => {
        setCopyModalOpen(false);
        loadAgents();
      }, [loadAgents]),
    });

  const reloadAgents = useCallback(() => {
    loadAgents(1);
  }, [loadAgents]);

  useEffect(() => {
    loadAgents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentStatusFilter, endAgentDate, startAgentDate]);

  const isOwner = useMemo<boolean>(() => {
    return currentUser?.id === activeAgent?.owner_id;
  }, [currentUser?.id, activeAgent?.owner_id]);

  const userAccessTier = useMemo<number>(() => {
    return getTierWeight(currentUser?.access_tier);
  }, [currentUser]);

  const canEdit = useMemo<boolean>(() => {
    return userAccessTier >= getTierWeight(UserTier.ADMIN);
  }, [userAccessTier]);

  const handleEdit = useCallback(() => {
    history.push(`${AGENT_EDIT_ROUTE}/${activeAgent?.id}`);
  }, [activeAgent?.id, history]);

  const canCopy = useMemo<boolean>(() => {
    return userAccessTier >= getTierWeight(UserTier.GAME_EDITOR);
  }, [userAccessTier]);

  const canArchive = useMemo<boolean>(() => {
    return (
      activeAgent?.status !== AgentStatus.ARCHIVED &&
      (userAccessTier >= getTierWeight(UserTier.ADMIN) ||
        (userAccessTier >= getTierWeight(UserTier.GAME_EDITOR) && isOwner))
    );
  }, [userAccessTier, isOwner, activeAgent?.status]);

  const navigateToAgent = useCallback(
    (id: string, event: React.MouseEvent) => {
      event.stopPropagation();
      history.push(`${AGENT_VIEW_ROUTE}/${id}`);
    },
    [history],
  );

  return {
    currentUser,
    adminUser,
    invitationsSubmittedBy: invitationsSubmittedBy?.data,
    invitationsCreatedBy: invitationsCreatedBy?.data,
    isInvitationsSubmittedByLoading,
    isInvitationsCreatedByLoading,
    isLoading:
      isCurrentUserLoading || isSendInvitationLoading || isUpdateUserLoading,
    invitationModalOpen,
    setInvitationModalOpen,
    handleSendInvitationClick,
    handleSendInvitation,
    formData,
    formDataSetters,
    accessTierModalOpen,
    setAccessTierModalOpen,
    handleUpdateAccessTierClick,
    handleUpdateAccessTier,
    currentUserAccessTier: unsavedAccessTier,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    agentStatusFilter,
    setAgentStatusFilter,
    agentsToShow,
    reloadAgents,
    isAgentLoading,
    nextAgentPage,
    prevAgentPage,
    loadNextAgent,
    loadPrevAgent,
    checkpoints: agentCheckpoints?.data || [],
    canEdit,
    handleEdit,
    canCopy,
    copyModalOpen,
    setCopyModalOpen,
    copyAgent,
    isCopyAgentLoading,
    canArchive,
    archiveModalOpen,
    setArchiveModalOpen,
    archiveAgent,
    isArchiveAgentLoading,
    pageNumber: prevAgentPage
      ? prevAgentPage + 1
      : nextAgentPage
        ? nextAgentPage - 1
        : 1,
    canLoadNext: !!nextAgentPage,
    canLoadPrev: !!prevAgentPage,
    startAgentDate,
    setStartAgentDate,
    endAgentDate,
    setEndAgentDate,
    instances: agentInstances?.data || [],
    navigateToAgent,
  };
};

export default useComponentProps;
